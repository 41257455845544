import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';

export const TEAMS = defineAction(
  'TEAMS',
  ['LOADING', 'SUCCESS', 'ERROR'],
  'teams',
);

const defaultState = Map({
  loading: false,
  list: Map(),
  rootsByEvent: undefined,
  error: undefined,
});

// Actions
const loaded = data => (
  { type: TEAMS.SUCCESS, data }
);

const loading = () => (
  { type: TEAMS.LOADING }
);

const loadingError = () => (
  { type: TEAMS.ERROR }
);

export function getGroups(ids, options) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.post('1/identity/groups/search', {
      id: ids,
      with: {
        ancestors: true,
      },
      ...options,
    })
      .then(({ data }) => {
        dispatch(loaded(data));
      })
      .catch(
        () => {
          dispatch(loadingError());
        },
      );
  };
}

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case TEAMS.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case TEAMS.ERROR: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case TEAMS.SUCCESS: {
      const map = {}; // make them accessible by id on this map
      let notUpdated = [];
      if (action.data) {
        action.data.forEach((item) => {
          const { children, descendants, ...values } = item;
          map[item.id] = values;
          if (children) {
            notUpdated = state
              .get('list')
              .filter(group => group.get('parent_id') === item.id)
              .map(group => group.get('id'))
              .toArray();

            children.forEach((child) => {
              map[child.id] = child;
              notUpdated = notUpdated.filter(id => id !== child.id);
            });
          }
          if (descendants) {
            descendants.forEach((child) => {
              map[child.id] = child;
            });
          }
        });
      }

      let newState = state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').mergeDeep(fromJS(map)));

      if (action.replace) {
        Object.keys(map).forEach((id) => {
          newState = newState.setIn(['list', id, 'memberships'], fromJS(map[id].memberships));
        });
        if (notUpdated) {
          notUpdated.forEach((id) => {
            newState = newState.deleteIn(['list', id]);
          });
        }
      }

      return newState;
    }
    default: {
      return state;
    }
  }
}
