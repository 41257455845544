import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { accountLogout } from '../ducks/auth';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
  }

  onSubmit = () => (e) => {
    e.preventDefault();
    this.props.history.push('/search/'.concat(encodeURIComponent(btoa(this.state.search))));
  }

  onLogout = () => () => {
    const { dispatch } = this.props;
    dispatch(accountLogout());
  }

  render() {
    return (
      <form onSubmit={this.onSubmit()}>
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <a href="/" className="navbar-brand col-sm-3 col-md-2 mr-0 text-white">DreamHack crew checkin</a>
          <input 
            className="form-control form-control-dark w-100 ml-1" 
            type="text" 
            placeholder="Search" 
            aria-label="Search" 
            onChange={(event) => this.setState({ search: event.target.value })}
            value={this.state.search}
          />
          <ul className="navbar-nav px-3">
            <li className="nav-item text-nowrap">
              <div className="nav-link" onClick={this.onLogout()}>Sign out</div>
            </li>
          </ul>
        </nav>
      </form>
    );
  }
}

export default withRouter(connect()(NavBar));
