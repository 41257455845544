import { connect } from "react-redux";
import Blockies from "react-blockies";
import Img from "react-image";
import PropTypes from "prop-types";
import React from "react";

//import { getUsers } from '../../../ducks/crew/users';
import Link from "./Link";

const propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  seed: PropTypes.string,
  users: PropTypes.shape({}),
  dispatch: PropTypes.func,
  clickable: PropTypes.bool
};

class UserImage extends React.Component {
  static defaultProps = {
    clickable: true
  };

  componentDidMount() {
    //const { dispatch, id } = this.props;
    //dispatch(getUsers(id));
  }

  render() {
    const { id, seed, users, className, size, clickable, style } = this.props;

    const user = users.get(id);
    const format = size <= 64 ? "square64" : "square160";
    const url = user && user.getIn(["avatar", format]);

    const blockiesSeed = (user && user.get("id")) || seed;
    const blockie = () =>
      blockiesSeed ? (
        <div
          className={className}
          style={{ width: size, height: size, ...style }}
        >
          <Blockies
            className={className}
            seed={blockiesSeed}
            color="#ff6600"
            bgColor="#1e1e20"
            size={8}
            scale={size / 8}
          />
        </div>
      ) : (
        <div
          className={className}
          style={{ width: size, height: size, ...style }}
        />
      );

    const image =
      (url && (
        <Img
          className={className}
          src={url}
          loader={<div style={{ width: size, height: size, ...style }} />}
          unloader={blockie()}
          style={{ width: size, height: size, ...style }}
        />
      )) ||
      blockie();

    return clickable && user ? (
      <Link to={"/crew/users/".concat(user.get("id"))}>{image}</Link>
    ) : (
      image
    );
  }
}

UserImage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    users: state.getIn(["users", "list"])
  };
}

export default connect(mapStateToProps)(UserImage);
