const dhid = typeof window !== 'undefined' ? window.dhid : undefined;

if (dhid !== undefined) {
  dhid.interceptors.response.use((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    return Promise.reject(response);
  }, error => Promise.reject(error));
}

export default dhid;
