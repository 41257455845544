import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEvents, selectEvent } from '../ducks/events';
import moment from 'moment';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

class Event extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getEvents());
  }

  render() {
    const { events, active, dispatch } = this.props;

    /* Sort all events from oldest to newest */
    const sortedEvents = events && events.sort((a, b) => (
      moment(a.get('start')).diff(b.get('start'))
    ));

    /* Put all upcoming events in a separate array */
    const upcomingEvents = sortedEvents && sortedEvents.filter(event => (
      moment(event.get('end')).isSameOrAfter(moment().startOf('day').subtract(4, 'weeks'))
    ));

    /* Get a list of all _upcoming_ events. Events that are over should not show up. */
    const filteredEvents = upcomingEvents && upcomingEvents.filter(event => (
      event.get('crew') &&
      moment().isBefore(moment(event.get('crew_end')))
    ));

    if ( !active && filteredEvents ) {
      dispatch(selectEvent(filteredEvents.first()));
    }

    return (
      <div className="d-flex flex-wrap px-3 mt-2">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret size="sm" color={active?'secondary':'danger'}>
          {(active && active.get('name')) || 'No event selected'} ({(filteredEvents && filteredEvents.size) || '0'})
          </DropdownToggle>
          <DropdownMenu>
            {filteredEvents && filteredEvents.map(e => (
              <DropdownItem onClick={() => {
                dispatch(selectEvent(e));
              }} key={e.get('id')}>{e.get('name')}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  events: state.getIn(['events', 'events']),
  active: state.getIn(['events', 'active']),
})
export default connect(mapStateToProps)(Event);
