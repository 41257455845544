import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { getGroups } from "../ducks/teams";

class Teams extends Component {
  componentWillMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(props) {
    const { dispatch, groups } = this.props;
    if (props.groups !== groups && props.groups && props.groups.size > 0) {
      dispatch(getGroups(Object.keys(props.groups.toJS())));
    }
  }

  render() {
    const { teams, groups, event, loading } = this.props;

    const alreadyAdded = [];
    const found =
      groups &&
      teams &&
      event &&
      groups
        .map((relation, groupId) => {
          // Loop thru ancestors to find the node with crew_team attribute
          let id = groupId;
          let node;
          do {
            node = teams.get(id); // Get the node
            if (
              node &&
              node.getIn(["attributes", "event"]) !== event.get("id")
            ) {
              return null;
            }
            id = node && node.get("parent_id");
          } while (node && !node.getIn(["attributes", "crew_team"]) && id); // Loop until we find a crew_team

          const team = node && node.getIn(["attributes", "crew_team"]) && node; // Suceess! we found it, return the node
          if (team) {
            if (alreadyAdded.indexOf(team.get("id")) !== -1) {
              return null;
            }
            alreadyAdded.push(team.get("id"));
          }
          return team;
        })
        .filter(team => !!team); // Remove all that was not found

    return (
      <div
        className={classnames({
          card: true,
          "bg-danger": !loading && found && found.size === 0
        })}
        style={{
          opacity: groups && groups.size > 0 ? 1 : 0.4
        }}
      >
        <div className="card-header">
          {found && found.size > 1 ? `Teams (${found.size})` : "Team"}
        </div>
        <div className="card-body">
          {found &&
            Object.values(
              found
                .map(team => <div key={team.get("id")}>{team.get("name")}</div>)
                .toJS()
            )}
          {!loading && found && found.size === 0 && <em>No team was found</em>}
          {loading && <i class="fa fa-spinner fa-spin" />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  event: state.getIn(["events", "active"]),
  teams: state.getIn(["teams", "list"]),
  loading: state.getIn(["teams", "loading"])
});
export default connect(mapStateToProps)(Teams);
