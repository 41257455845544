import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import { Button } from 'reactstrap';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import dhid from './dhid';
import NavBar from './components/NavBar';
import Event from './components/Event';
import Scanner from './components/Scanner';
import History from './components/History';
import Search from './components/Search';
import User from './components/User';
import { accountLogout } from './ducks/auth';
import { getUser } from './ducks/auth';

class App extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getUser());
  }

  onLogin = () => () => {
    window.location.href = `${dhid.getWebUrl()}login`;
  }

  onLogout = () => () => {
    const { dispatch } = this.props;
    dispatch(accountLogout());
  }

  render() {
    const { attempt, user } = this.props;

    return (
      <Router>
        <React.Fragment>
          {(!user || !user.getIn(['access','permissions', 'crew.checkin'])) && 
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
              {!attempt && 
                <i className="fa fa-refresh fa-5x fa-spin"></i>
              }
              {attempt && !user && 
                <Button color="primary" onClick={this.onLogin()}>Login</Button>
              }
              {user && !user.getIn(['access','permissions', 'crew.checkin']) &&
                <React.Fragment>
                  <div className="mb-2">To bad! You dont have access to checkin crew.</div>
                  <Button color="primary" onClick={this.onLogout()}>Logout</Button>
                </React.Fragment>
              }
            </div>
          }
          {user && user.getIn(['access','permissions', 'crew.checkin']) && 
          <React.Fragment>
            <NavBar /> 

            <div className="container-fluid">
              <div className="row">
                <nav className="col-md-2 d-none d-md-block bg-dark sidebar">
                  <div className="sidebar-sticky">
         
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                      <span>Event</span>
                    </h6>
                    <Event />

                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                      <span>Scanner</span>
                      <div className="d-flex align-items-center text-muted">
                        <span className="fa fa-qrcode"></span>
                      </div>
                    </h6>
                    <Scanner />

                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                      <span>History</span>
                      <div className="d-flex align-items-center text-muted">
                        <span className="fa fa-history"></span>
                      </div>
                    </h6>
                    <History />
                  </div>
                </nav>

                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                  <Switch>
                    <Route path="/search/:search" component={Search} />
                    <Route path="/user/:user" component={User} />
                  </Switch>
                </main>
              </div>
            </div>
          </React.Fragment>
          }
        </React.Fragment>
			</Router>
    );
  }
}

const mapStateToProps = state => ({
  attempt: state.getIn(['auth', 'attempt']),
  user: state.getIn(['auth', 'data']),
})

export default connect(mapStateToProps)(App);
