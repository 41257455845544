import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';
import { AUTH } from './auth';
import { historyAppend } from './history';

const USERS = defineAction(
  'USERS',
  ['LOADING', 'SUCCESS', 'ERROR', 'LOADING_SEARCH', 'SUCCESS_SEARCH', 'ERROR_SEARCH',  'SUCCESS_USERDATA'],
  'users',
);

const defaultState = Map({
  list: Map(),
  loaded: Map(),
  userData: Map(),
  loading: false,
  error: false,
  searchQuery: '',
  searchResult: Map(),
  searchError: false,
});

const loaded = data => (
  { type: USERS.SUCCESS, data }
);

const loadedSearch = data => (
  { type: USERS.SUCCESS_SEARCH, data }
);

const loadedUserData = data => (
  { type: USERS.SUCCESS_USERDATA, data }
);

const loading = (id) => (
  { type: USERS.LOADING, id }
);

const loadingSearch = query => (
  { type: USERS.LOADING_SEARCH, query }
);

const loadingError = error => (
  { type: USERS.ERROR, error }
);

const loadingSearchError = error => (
  { type: USERS.ERROR_SEARCH, error }
);

export function getUser(id) {
  return (dispatch, getState) => {
    if (getState().getIn(['users', 'loaded', id])) {
        return;
    }

    dispatch(loading(id));
    return dhid.post('1/identity/users/search', {
      id,
    })
      .then(({ data }) => {
        dispatch(historyAppend(id, data[id] ? data[id].name : id));
        dispatch(loaded(data));
        return data;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        throw error;
      });
  };
}

export function getUserData(id) {
  return (dispatch) => {
    dispatch(loading());
    return dhid.get(`1/identity/users/${id}/data`)
      .then(({ data }) => {
        dispatch(loadedUserData(data));
        return data;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        throw error;
      });
  };
}

export function search(query) {
  return (dispatch) => {
    dispatch(loadingSearch());
    return dhid.post('1/identity/users/search', {
      query,
      only: {
        roles: ['crew'],
      },
    })
      .then(({ data }) => {
        dispatch(loadedSearch(data));
        return data;
      })
      .catch((error) => {
        dispatch(loadingSearchError(error));
        throw error;
      });
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USERS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        .set('list', state.get('list').merge(fromJS(action.data)));
    }
    case USERS.SUCCESS_SEARCH: {
      return state
        .set('loading', false)
        .set('errorSearch', false)
        .set('searchResult', fromJS(action.data))
        .set('list', state.get('list').merge(fromJS(action.data)));
    }
    case USERS.SUCCESS_USERDATA: {
      return state
        .set('userData', state.get('userData').merge(fromJS(action.data)));
    }
    case USERS.LOADING: {
      const nstate = action.id ? state.setIn(['loaded', action.id], true) : state;
      return nstate
        .set('error', false) // Clear the previous error
        .set('loading', true);
    }
    case USERS.LOADING_SEARCH: {
      return state
        .set('errorSearch', false) // Clear the previous error
        .set('searchResult', Map())
        .set('loading', true)
        .set('searchQuery', action.query);
    }
    case USERS.ERROR: {
      return state
        .set('loading', false)
        .set('searchResult', Map())
        .set('error', action.error);
    }
    case USERS.ERROR_SEARCH: {
      return state
        .set('loading', false)
        .set('errorSearch', action.error);
    }
    case AUTH.LOGOUT:
      return state
        .set('loading', false)
        .set('error', false)
        .set('list', Map());
    default: {
      return state;
    }
  }
}
