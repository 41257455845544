import { combineReducers } from 'redux-immutable';
import auth from './auth';
import checkins from './checkins';
import history from './history';
import events from './events';
import scanner from './scanner';
import teams from './teams';
import users from './users';

const rootReducer = combineReducers({
  auth,
  checkins,
  history,
  events,
  scanner,
  teams,
  users,
});

export default rootReducer;
