import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser, getUserData } from "../ducks/users";
import {
  getCheckins,
  checkin,
  updateCheckin,
  undoCheckin
} from "../ducks/checkins";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import Immutable from "immutable";
import Moment from "react-moment";
import moment from "moment";
import classnames from "classnames";

import UserImage from "./UserImage";
import UserValue from "./UserValue";
import Teams from "./Teams";

class User extends Component {
  componentWillMount() {
    const { dispatch, match } = this.props;
    dispatch(getUser(match.params.user));
    dispatch(getUserData(match.params.user));
    dispatch(getCheckins(match.params.user));
  }

  componentWillReceiveProps(props) {
    if (props.match.params.user !== this.props.match.params.user) {
      const { dispatch, match } = props;
      dispatch(getUser(match.params.user));
      dispatch(getUserData(match.params.user));
      dispatch(getCheckins(match.params.user));
    }
  }

  onCheckin = () => {
    const { users, match, event } = this.props;
    const user = users.get(match.params.user);

    this.props.dispatch(checkin(user.get("id"), event.get("id")));
  };

  onCheckinUndo = checkinId => () => {
    const { dispatch } = this.props;
    dispatch(undoCheckin(checkinId));
  };

  onDeliver = (id, deliverable, delivered = true) => () => {
    const { dispatch, account } = this.props;

    dispatch(
      updateCheckin(id, {
        [deliverable]: {
          delivered,
          delivered_by: account.get("id"),
          delivered_at: moment()
        }
      })
    );
  };

  onDeliverAll = (id, deliverables) => () => {
    const { dispatch, account } = this.props;

    const attributes = Object.keys(deliverables).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          delivered: true,
          delivered_by: account.get("id"),
          delivered_at: moment()
        }
      }),
      {}
    );

    dispatch(updateCheckin(id, attributes));
  };

  render() {
    const {
      loading,
      users,
      userdata,
      match,
      event,
      checkins,
      checkins_loading,
      checkins_error
    } = this.props;

    const user = users.get(match.params.user);
    const data = user && userdata.get(user.get("id"));

    const eventData =
      data &&
      Object.keys(data.toJS())
        .filter(key => key.substr(0, 6) === "event.")
        .reduce((obj, key) => {
          obj[key.substr(6)] = data.get(key);
          return obj;
        }, {});

    const contactData = data && data.get("contact.social");

    const checkedIn =
      checkins &&
      event &&
      user &&
      checkins.find(
        checkin =>
          checkin.get("event_id") === event.get("id") &&
          checkin.get("user_id") === user.get("id")
      );

    const canUndoCheckin =
      !!checkedIn && checkedIn.get("created_by") === window.dhid.getUserID();

    const deliverables = {
      tshirt: "T-shirt",
      gift: "Gift",
      badge: "Badge",
      wristband: "Wristband",
      foodtickets: "Food tickets",
      codeofconduct: "Code of conduct"
    };

    const notDelivered =
      checkedIn &&
      Object.keys(deliverables).find(
        key => !checkedIn.getIn(["attributes", key])
      );

    const deliverablesWithData = Object.keys(deliverables).map(key => ({
      key,
      item: deliverables[key],
      delivered: !!(
        checkedIn && checkedIn.getIn(["attributes", key, "delivered"])
      ),
      delivered_by:
        checkedIn && checkedIn.getIn(["attributes", key, "delivered_by"]),
      delivered_at:
        checkedIn && checkedIn.getIn(["attributes", key, "delivered_at"])
    }));

    return (
      <div className="d-flex flex-wrap mt-2">
        <div className="col-md-3 mt-4">
          <div className="card d-flex flex-column">
            <div className="card-img-top flex-grow-1 d-flex align-items-center justify-content-center">
              <UserImage
                id={user && user.get("id")}
                clickable={false}
                style={{ width: "100%" }}
              />
            </div>
            <div className="card-body flex-grow-0">
              {loading && <i class="fa fa-spinner fa-spin" />}
              <h5 className="card-title">
                {(user && user.get("name")) || "-"}
              </h5>
              <p className="card-text">
                {(user && user.get("first_name")) || "-"}{" "}
                {user && user.get("last_name")}
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 mt-4">
          <Teams groups={user && user.get("groups")} />

          <div
            className="card mt-4"
            style={{
              opacity:
                loading || (contactData && contactData.size > 0) ? 1 : 0.4
            }}
          >
            <div className="card-header">Contact</div>
            <div className="card-body">
              {loading && <i class="fa fa-spinner fa-spin" />}
              {contactData &&
                contactData.map(row => (
                  <div key={row.get("type")}>
                    <strong>{row.get("type")}:</strong> {row.get("value")}
                  </div>
                ))}
            </div>
          </div>

          <div
            className="card mt-4"
            style={{
              opacity:
                loading || (eventData && Object.keys(eventData).length > 0)
                  ? 1
                  : 0.4
            }}
          >
            <div className="card-header">Event information</div>
            <div className="card-body">
              {loading && <i class="fa fa-spinner fa-spin" />}
              {eventData &&
                Object.keys(eventData).map(key => (
                  <div key={key}>
                    <strong>{key}:</strong>{" "}
                    {eventData[key] instanceof Immutable.List
                      ? eventData[key].join(", ")
                      : eventData[key]}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="col-md-3 mt-4">
          <div
            className={classnames({
              card: true,
              "bg-danger": checkins_error
            })}
          >
            <div className="card-body">
              {!canUndoCheckin && (
                <Button
                  color={checkedIn ? "success" : "primary"}
                  size="lg"
                  block
                  disabled={
                    !event ||
                    checkedIn ||
                    checkins_loading ||
                    !user ||
                    !event ||
                    checkins_error
                  }
                  onClick={this.onCheckin}
                >
                  {checkedIn ? "Checked in" : "Check in"}
                  {event && (
                    <React.Fragment>
                      {" "}
                      to <strong>{event.get("shortcode").toUpperCase()}</strong>
                    </React.Fragment>
                  )}
                </Button>
              )}
              {canUndoCheckin && (
                <Button
                  color={"success"}
                  size="lg"
                  block
                  onClick={this.onCheckinUndo(checkedIn.get("id"))}
                >
                  Undo check in
                  {event && (
                    <React.Fragment>
                      {" "}
                      to <strong>{event.get("shortcode").toUpperCase()}</strong>
                    </React.Fragment>
                  )}
                </Button>
              )}
              {checkins_error && (
                <span>{checkins_error.response.data.message}</span>
              )}
              {checkedIn && (
                <div className="text-center mt-1">
                  Checked in{" "}
                  <strong>
                    <Moment fromNow withTitle>
                      {checkedIn.get("created_at")}
                    </Moment>
                  </strong>
                  {checkedIn.get("created_by") && (
                    <React.Fragment>
                      {" by "}
                      <UserValue
                        id={checkedIn.get("created_by")}
                        field="name"
                      />
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </div>
          {!checkins_error && (
            <React.Fragment>
              <div className="card mt-4">
                <div className="card-body">
                  {deliverablesWithData.map(delivery => (
                    <div className="row my-1">
                      <div className="col-sm-6">{delivery.item}</div>
                      <div className="col-sm-6 text-right">
                        <Button
                          color={!delivery.delivered ? "primary" : "success"}
                          onClick={this.onDeliver(
                            checkedIn && checkedIn.get("id"),
                            delivery.key,
                            !delivery.delivered
                          )}
                          disabled={
                            !event || !checkedIn || checkins_loading || !user
                          }
                        >
                          <div>
                            {delivery.delivered ? "Undo deliver" : "Deliver"}
                          </div>
                        </Button>
                      </div>
                    </div>
                  ))}

                  {notDelivered && (
                    <Button
                      color="primary"
                      size="lg"
                      block
                      disabled={
                        !event || !checkedIn || checkins_loading || !user
                      }
                      onClick={this.onDeliverAll(
                        checkedIn && checkedIn.get("id"),
                        deliverables
                      )}
                      className="mt-3"
                    >
                      Deliver all
                    </Button>
                  )}
                </div>
              </div>
              <div className="card mt-4">
                <div className="card-body">
                  {deliverablesWithData.map(
                    delivery =>
                      delivery.delivered_by && (
                        <div key={delivery.key}>
                          <strong>{delivery.key}</strong> was{" "}
                          <strong>
                            {!delivery.delivered ? "not delivered" : "deliver"}
                          </strong>{" "}
                          by{" "}
                          <strong>
                            <UserValue
                              id={delivery.delivered_by}
                              field="name"
                            />
                          </strong>
                          {delivery.delivered_at && (
                            <React.Fragment>
                              {" about "}
                              <strong>
                                <Moment fromNow withTitle>
                                  {delivery.delivered_at}
                                </Moment>
                              </strong>
                            </React.Fragment>
                          )}
                        </div>
                      )
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.getIn(["auth", "data"]),
  loading: state.getIn(["users", "loading"]),
  users: state.getIn(["users", "list"]),
  userdata: state.getIn(["users", "userData"]),
  event: state.getIn(["events", "active"]),
  checkins: state.getIn(["checkins", "list"]),
  checkins_loading: state.getIn(["checkins", "loading"]),
  checkins_error: state.getIn(["checkins", "error"])
});
export default withRouter(connect(mapStateToProps)(User));
