import React from 'react';

/* global Primus */


const Context = React.createContext();

class Websocket extends React.Component {
  constructor() {
    super();
    this.state = {
      primus: null,
    };
  }

  componentWillMount() {
    if (typeof Primus === 'undefined') {
        return;
    }
    const primus = Primus.connect("wss://api.dhdev.se/1/socket/connection", {  });

    this.setState({
      primus,
    });
  }

  render() {
    const { children } = this.props;
    return (
      <Context.Provider value={this.state}>{children}</Context.Provider>
    );
  }
}

export { Context };
export default Websocket;
