import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';

const CHECKINS = defineAction(
  'CREW_CHECKINS',
  ['LOADING', 'SUCCESS', 'ERROR', 'CHECKIN_USER', 'CHECKIN_USER_SUCCES', 'CHECKIN_UNDO'],
  'checkins',
);

const defaultState = Map({
  loading: false,
  list: Map(),
  error: undefined,
});

// Events
export const loaded = (data, permissions) => (
  { type: CHECKINS.SUCCESS, data, permissions }
);

export const loadedUndo = id => (
  { type: CHECKINS.CHECKIN_UNDO, id }
);

const loading = () => (
  { type: CHECKINS.LOADING }
);

const loadingError = error => (
  { type: CHECKINS.ERROR, error }
);

// Actions
export function getCheckins(user_id) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.request(`1/crew/checkins/user/${user_id}`)
      .then(({ data }) => {
        dispatch(loaded(data));
      })
      .catch((err) => {
        dispatch(loadingError(err));
      });
  };
}

export function checkin(user_id, event_id) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.post('1/crew/checkins', {
      user_id,
      event_id,
    })
      .then(({ data }) => {
        dispatch(loaded({
          [data.id]: data,
        }));
      })
      .catch((err) => {
        dispatch(loadingError(err));
      });
  };
}

export function updateCheckin(id, attributes) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.put(`1/crew/checkins/${id}`, {
      attributes,
    })
      .then(({ data }) => {
        dispatch(loaded({
          [data.id]: data,
        }));
      })
      .catch((err) => {
        dispatch(loadingError(err));
      });
  };
}

export function undoCheckin(id) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.delete(`1/crew/checkins/${id}`)
      .then(({ data }) => {
        dispatch(loadedUndo(id));
      })
      .catch(() => {
        dispatch(loadingError());
      });
  }
}

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CHECKINS.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case CHECKINS.ERROR: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case CHECKINS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').merge(fromJS(action.data)))
    }
    case CHECKINS.CHECKIN_UNDO: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').remove(action.id));
    }
    default: {
      return state;
    }
  }
}
