import { Map } from 'immutable';
import { defineAction } from 'redux-define';

const HISTORY = defineAction(
  'HISTORY',
  ['APPEND'],
  'history',
);

const defaultState = Map({
  history: [],
});

export function historyAppend(userId, name) {
  return { type: HISTORY.APPEND, userId, name };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case HISTORY.APPEND: {
      const history = state.get('history');

      const obj = {
        name: action.name,
        id: action.userId,
      };

      if (!history.find(o => o.id === action.userId)) {
        return state.set('history', [obj, ...history].slice(0, 5));
      }

      return state;
    }
    default: {
      return state;
    }
  }
}
