import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';

const EVENTS = defineAction(
  'EVENTS',
  ['LOADING', 'SUCCESS', 'ERROR', 'SELECT'],
  'events',
);

const defaultState = Map({
  events: Map(),
  active: null,
  loading: false,
  error: false,
  loaded: false,
});

const listSplashEvents = events => (
  { type: EVENTS.SUCCESS, events }
);

export const selectEvent = e => (
  { type: EVENTS.SELECT, event: e }
);

const loading = () => (
  { type: EVENTS.LOADING }
);

const loadingError = () => (
  { type: EVENTS.ERROR }
);

let loadTriggered = false;
export function getEvents() {
  if (loadTriggered) {
    return () => {};
  }
  loadTriggered = true;

  return (dispatch) => {
    dispatch(loading());

    return dhid.request('1/content/event/public')
      .then(({ data }) => {
        dispatch(listSplashEvents(data));
      })
      .catch(
        () => {
          dispatch(loadingError());
        },
      );
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case EVENTS.SUCCESS: {
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('events', fromJS(action.events))
        .set('error', false);
    }
    case EVENTS.SELECT: {
      return state
        .set('active', action.event);
    }
    case EVENTS.LOADING: {
      return state
        .set('loading', true);
    }
    case EVENTS.ERROR: {
      return state
        .set('loading', false)
        .set('error', true);
    }
    default: {
      return state;
    }
  }
}
