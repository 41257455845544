import React, { Component } from "react";
import { connect } from "react-redux";
import { search } from "../ducks/users";
import { List } from "immutable";

import UserImage from "./UserImage";

class Search extends Component {
  componentWillMount() {
    this.doSearch(this.props);
  }

  componentWillReceiveProps(props) {
    if (props.match.params.search !== this.props.match.params.search) {
      this.doSearch(props);
    }
  }

  doSearch(props) {
    const { dispatch, match } = props;
    dispatch(search(atob(decodeURIComponent(match.params.search))));
  }

  onClick = id => () => {
    this.props.history.push(`/user/${id}`);
  };

  render() {
    const { result, error, loading } = this.props;

    return (
      <div className="d-flex flex-wrap mt-2">
        {error && (
          <div className="alert alert-danger mt-4">
            Search failed:{" "}
            {typeof error.response.data.error !== "undefined"
              ? error.response.data.error
              : error.message || "Unknown error"}
          </div>
        )}
        {!loading && !error && result.size === 0 && (
          <div className="alert alert-warning mt-4">
            Didn't find anything maching your search :(
          </div>
        )}
        {result &&
          Object.values(
            result
              .sort(
                (a, b) =>
                  b.get("groups", List()).size - a.get("groups", List()).size
              )
              .map(user => (
                <div
                  className="col-md-3 mt-4"
                  key={user.get("id")}
                  style={{
                    cursor: "pointer",
                    opacity: user.get("groups", List()).size > 0 ? 1 : 0.3
                  }}
                  onClick={this.onClick(user.get("id"))}
                >
                  <div
                    className="card d-flex flex-column"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="card-img-top flex-grow-1 d-flex align-items-center justify-content-center"
                      style={{
                        width: "100%",
                        paddingTop: "100%",
                        position: "relative"
                      }}
                    >
                      <UserImage
                        id={user.get("id")}
                        clickable={false}
                        style={{
                          width: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0
                        }}
                      />
                    </div>
                    <div className="card-body flex-grow-0">
                      <h5 className="card-title">{user.get("name")}</h5>
                      <p className="card-text">
                        {user.get("first_name")} {user.get("last_name")}
                      </p>
                    </div>
                  </div>
                </div>
              ))
              .toJS()
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  result: state.getIn(["users", "searchResult"]),
  error: state.getIn(["users", "errorSearch"]),
  loading: state.getIn(["users", "loading"])
});
export default connect(mapStateToProps)(Search);
