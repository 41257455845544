import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

class Search extends Component {
  componentWillMount() {
    //const { dispatch, match } = this.props;
  }

  render() {
    const { history } = this.props;

    return (
      <div className="d-flex flex-wrap mt-2">
        <div className="mt-2 px-3">
          {history && history.map(h => (
            <NavLink to={`/user/${h.id}`} key={h.key}>
              <p>{h.name}</p>
            </NavLink>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  history: state.getIn(['history', 'history']),
})
export default withRouter(connect(mapStateToProps)(Search));
