import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';

export const AUTH = defineAction(
  'AUTH',
  ['LOADING', 'ERROR', 'SUCCESS','LOGOUT'],
  'auth',
);

const defaultState = Map({
  data: undefined,
  loading: false,
  error: undefined,
  attempt: false, 
});


const loading = () => (
  { type: AUTH.LOADING }
);

const loadingError = error => (
  { type: AUTH.ERROR, error }
);

const loaded = data => (
  { type: AUTH.SUCCESS, data }
);

const logout = () => (
  { type: AUTH.LOGOUT }
);

let loadTriggered = false;

export function getUser() {
  if (loadTriggered) {
    return () => {};
  }
  loadTriggered = true;

  return (dispatch) => {
    dispatch(loading());
    if (dhid && dhid.getUserID()) {
      dispatch(loaded(dhid.getUser()));
    } else {
      dispatch(loaded(undefined));
    }
  };
}

export function accountLogout() {
  return (dispatch) => {
    dispatch(loading());
    dhid.request(`${dhid.getBaseUrl()}/logout`, {
      method: 'post',
      data: {},
      withCredentials: true,
    })
      .then(({ data }) => {
        dhid.setUser(undefined);
        return dispatch(logout());
      })
      .catch(error => (
        dispatch(loadingError(error))
      ));
  };
}


export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH.LOADING: 
      return state
        .set('error', undefined)
        .set('loading', true);
    case AUTH.SUCCESS: 
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('data', fromJS(action.data))
        .set('attempt', true);
    case AUTH.ERROR: 
      return state
        .set('loading', false)
        .set('error', action.error);
    case AUTH.LOGOUT:
      return state
        .set('loading', false)
        .set('error', false)
        .set('data', undefined);
    default: {
      return state;
    }
  }
}
