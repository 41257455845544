import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getUser } from '../ducks/users';
import Link from './Link';

const propTypes = {
  id: PropTypes.string,
  field: PropTypes.string,
  dispatch: PropTypes.func,
  users: PropTypes.shape({}),
  clickable: PropTypes.bool,
};

class UserValue extends React.Component {
  static defaultProps = {
    clickable: true,
  }

  componentDidMount() {
    const { dispatch, id } = this.props;
    if (id) {
      dispatch(getUser(id));
    }
  }

  componentWillReceiveProps(props) {
    const { dispatch, id } = props;
    if (id) {
      dispatch(getUser(id));
    }
  }

  render() {
    const {
      field, users, id, clickable,
    } = this.props;
    const user = users.get(id);
    const value = (user && (
      <React.Fragment>
        {user.get(field)}
      </React.Fragment>
    )) || '-';
    return clickable && user ? <Link to={'/user/'.concat(user.get('id'))}>{value}</Link> : value;
  }
}

UserValue.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    users: state.getIn(['users', 'list']),
    error: state.getIn(['users', 'error']),
  };
}


export default connect(mapStateToProps)(UserValue);
