import React from 'react';
import { connect } from 'react-redux';
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { Context as Websocket } from './Websocket';

/* global Instascan */

const makeid = () => {
	var text = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (var i = 0; i < 5; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
};

class Scanner extends React.Component {
  static contextType = Websocket;

  constructor() {
    super();
    if ( !localStorage.getItem('scanner-id') ) {
      localStorage.setItem('scanner-id', makeid());
    }

    this.state = {
      cameras: [],
      started: false,
			id: localStorage.getItem('scanner-id'),
    };

    this.preview = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;

    const scanner = new Instascan.Scanner({ 
      video: this.preview.current,
      captureImage: true,
    });
    scanner.addListener('scan', function (content, image) {
      //dispatch(scan(content, image));
      history.push( `/user/${content}` );
    });
    this.setState({
      scanner,
    });

    const self = this;
    const checkCameras = () => {
      Instascan.Camera.getCameras().then(function (cameras) {
        self.setState({
          cameras,
        });

        if (cameras.length > 0 && !self.state.started) {
          scanner.start(cameras[0]);
          self.setState({
            started: true,
          });
        }

        if ( cameras.length === 0 ) {
          scanner.stop();
          self.setState({
            started: false,
          });
        }
      });
    };
    setInterval(checkCameras, 1000);
    checkCameras();
  }

  render() {
    const { cameras, scanner, id } = this.state;
    return (
      <React.Fragment>
        <div className="px-3 mt-1 mb-1">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret size="sm">
              Change camera ({cameras.length})
            </DropdownToggle>
            <DropdownMenu>
              {cameras.map(camera => (
                <DropdownItem onClick={() => {
                  scanner.stop();
                  scanner.start(camera);
                }} key={camera.id}>{camera.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>{' '}
          <Button color="secondary" size="sm" disabled>Open slave tab</Button>
        </div>
        <div className="scanner-container">
					<div style={{position: 'absolute', top: '10px', left: '10px', zIndex: 10 }}>{id}</div>
          <video className="scanner" ref={this.preview}/>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect()(Scanner));
