import { Map, List } from 'immutable';
import { defineAction } from 'redux-define';

const SCANNER = defineAction(
  'SCANNER',
  ['SCAN'],
  'scanner',
);

const defaultState = Map({
  history: List(),
});

export const scan = (data, image) => dispatch => dispatch({ type: SCANNER.SCAN, data, image });

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SCANNER.SCAN: {
      return state
        .set('history', state.get('history').push(Map({
          data: action.data,
          image: action.image,
        })));
    }
    default: {
      return state;
    }
  }
}
